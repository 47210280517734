import {API, Resource} from '@coveo/platform-client';
import {
    BatchAnswerListQueryParams,
    BatchAnswerListResponse,
    BatchAnswerPathParams,
    CreateBatchAnswerBodyParams,
    CreateBatchAnswerResponse,
    RenameBatchAnswerBodyParams,
} from '../../../interfaces/knowledge/BatchAnswerTypes';
import {MockResponseParams} from '../../../interfaces/MockResponseInterfaces';
import {shouldMockBatchAnswer} from '../ShouldMockBatchAnswer';
import {createMockBatchAnswerListResponse, createMockEvaluationCreationResponse} from './BatchAnswerEvaluations.mocks';

const twoSeconds = 2000;
const totalBatchAnswerMockEntries = 10;

// TODO: Remove call mocks https://coveord.atlassian.net/browse/SVCC-4798
export class EvaluationResource extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/answer/v1/evaluations`;

    list(
        queryParams?: BatchAnswerListQueryParams,
        mockingParams?: MockResponseParams,
    ): Promise<BatchAnswerListResponse> {
        if (shouldMockBatchAnswer(mockingParams?.isMocking)) {
            return new Promise<BatchAnswerListResponse>((resolve) => {
                setTimeout(() => {
                    resolve(
                        createMockBatchAnswerListResponse(queryParams?.filter)(
                            mockingParams?.nbOfMockEntry ?? totalBatchAnswerMockEntries,
                            queryParams?.page,
                        ) as unknown as BatchAnswerListResponse,
                    );
                }, twoSeconds);
            });
        }
        return this.api.get<BatchAnswerListResponse>(this.buildPath(`${EvaluationResource.baseUrl}`, queryParams));
    }

    create(
        bodyParams: CreateBatchAnswerBodyParams,
        mockingParams?: MockResponseParams,
    ): Promise<CreateBatchAnswerResponse> {
        if (shouldMockBatchAnswer(mockingParams?.isMocking)) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(createMockEvaluationCreationResponse(bodyParams));
                }, twoSeconds);
            });
        }
        return this.api.post<CreateBatchAnswerResponse>(EvaluationResource.baseUrl, bodyParams);
    }

    rename(
        pathParams: BatchAnswerPathParams,
        bodyParams: RenameBatchAnswerBodyParams,
        mockingParams?: MockResponseParams,
    ): Promise<CreateBatchAnswerResponse> {
        if (shouldMockBatchAnswer(mockingParams?.isMocking)) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(createMockEvaluationCreationResponse(bodyParams as CreateBatchAnswerBodyParams));
                }, twoSeconds);
            });
        }
        return this.api.put<CreateBatchAnswerResponse>(
            `${EvaluationResource.baseUrl}/${pathParams.evaluationId}/name`,
            bodyParams,
        );
    }

    delete(pathParams: BatchAnswerPathParams, mockingParams?: MockResponseParams): Promise<void> {
        if (shouldMockBatchAnswer(mockingParams?.isMocking)) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, twoSeconds);
            });
        }
        return this.api.delete(`${EvaluationResource.baseUrl}/${pathParams.evaluationId}`);
    }
}
