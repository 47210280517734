const registry = new Map();

const register = (key: any, value: any): void => {
    registry.set(key, value);
};

/* eslint-disable no-redeclare */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
// These two eslint rules prevent from doing proper function types overloading
function get<R = any>(key: string): R;
function get<R = any>(key: string, options?: {optional?: boolean}): R | undefined;
function get<R = any>(key: string, options?: {optional?: boolean}): R | undefined {
    if (!options?.optional && !registry.has(key)) {
        throw new Error(`Key "${key}" does not exist in the registry.`);
    }
    return registry.get(key);
}

const clear = (): void => {
    registry.clear();
};

const toObject = (): any => Object.fromEntries(registry);

export const Registry = {
    /**
     * Registers a value at the specified key in the registry.
     *
     * @param key The unique identifier with which the value can be retrieved.
     * @param value The value to store in the registry.
     */
    register,

    /**
     * Retrieves the value at the specified key in the registry.
     *
     * @param key The unique identifier with which the value can be retrived.
     * @param options An optional object to customize the behavior of the function.
     * - `optional` If true, the function will return undefined instead of throwing an error if the key does not exist.
     * @returns The value stored at the specified key.
     * @throws An error if the key has never been registered in the registry.
     */
    get,

    /**
     * Erases all the information stored in the registry.
     * Useful during tests to ensure tests independance.
     */
    clear,

    /**
     * @returns The content of the registry.
     */
    toObject,
};

export default Registry;
