import {API, Resource} from '@coveo/platform-client';
import {BatchAnswerDatasetsResource} from './datasets';
import {EvaluationResource} from './evaluations';
import {EvaluationRunResource} from './runs';

export class BatchAnswer extends Resource {
    dataset: BatchAnswerDatasetsResource;
    evaluation: EvaluationResource;
    run: EvaluationRunResource;

    constructor(api: API, serverlessApi: API) {
        super(api, serverlessApi);

        this.dataset = new BatchAnswerDatasetsResource(api, serverlessApi);
        this.evaluation = new EvaluationResource(api, serverlessApi);
        this.run = new EvaluationRunResource(api, serverlessApi);
    }
}
