import {API, Resource} from '@coveo/platform-client';
import {AnswerConfigurationResource} from './configuration/AnswerConfiguration';
import {AnswerEvaluationResource} from './evaluation/AnswerEvaluationResource';
import {AnswerRuleResource} from './rule/AnswerRuleResource';

export class Answer extends Resource {
    configuration: AnswerConfigurationResource;
    evaluation: AnswerEvaluationResource;
    rule: AnswerRuleResource;

    constructor(api: API, serverlessApi: API) {
        super(api, serverlessApi);

        this.configuration = new AnswerConfigurationResource(api, serverlessApi);
        this.evaluation = new AnswerEvaluationResource(api, serverlessApi);
        this.rule = new AnswerRuleResource(api, serverlessApi);
    }
}
