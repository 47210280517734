import {API, Resource} from '@coveo/platform-client';
import {PreviewResultModel, SynonymsPreviewRequestModel} from '../../interfaces';

export class SynonymRulePreview extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/commerce/v2/search/preview/synonyms`;

    create(options: SynonymsPreviewRequestModel, trackingId: string) {
        return this.api.post<PreviewResultModel>(this.buildPath(SynonymRulePreview.baseUrl, {trackingId}), options);
    }

    update(options: SynonymsPreviewRequestModel, trackingId: string, synonymRuleId: string) {
        return this.api.put<PreviewResultModel>(
            this.buildPath(`${SynonymRulePreview.baseUrl}/${synonymRuleId}`, {trackingId}),
            options,
        );
    }

    get(options: SynonymsPreviewRequestModel, synonymRuleId: string) {
        return this.api.get<PreviewResultModel>(
            this.buildPath(`${SynonymRulePreview.baseUrl}/${synonymRuleId}`, options),
        );
    }
}
