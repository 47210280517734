import {API, PageModel, Resource} from '@coveo/platform-client';
import {BaseRuleModel, RuleDetailsModel, RulesListOptions} from '../../interfaces';

export class RuleDetails extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/commerce/v3/rules`;

    list<T extends BaseRuleModel>(options: RulesListOptions): Promise<PageModel<RuleDetailsModel<T>>> {
        return this.api.get<PageModel<RuleDetailsModel<T>>>(this.buildPath(`${RuleDetails.baseUrl}`, options));
    }

    get<T extends BaseRuleModel>(ruleId: string): Promise<RuleDetailsModel<T>> {
        return this.api.get(`${RuleDetails.baseUrl}/${ruleId}`);
    }

    create<T extends BaseRuleModel>(ruleDetails: RuleDetailsModel<T>): Promise<RuleDetailsModel<T>> {
        return this.api.post(`${RuleDetails.baseUrl}`, ruleDetails);
    }

    update<T extends BaseRuleModel>(ruleId: string, ruleDetails: RuleDetailsModel<T>): Promise<RuleDetailsModel<T>> {
        return this.api.put(`${RuleDetails.baseUrl}/${ruleId}`, ruleDetails);
    }

    delete(ruleId: string) {
        return this.api.delete(`${RuleDetails.baseUrl}/${ruleId}`);
    }
}
