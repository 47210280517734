import {API, Resource} from '@coveo/platform-client';

import {
    BatchAnswerGetDatasetListResponse,
    BatchAnswerGetDatasetResponse,
    CreateBatchAnswerDatasetBodyParams,
    DatasetQueryParams,
    DeleteBatchAnswerDatasetPathParams,
} from '../../../interfaces/knowledge/BatchAnswerTypes';

import {MockResponseParams} from '../../../interfaces';
import {shouldMockBatchAnswer} from '../ShouldMockBatchAnswer';
import {createMockBatchAnswerDataset, createMockBatchAnswerDatasetListResponse} from './BatchAnswerDatasets.mocks';

const twoSeconds = 2000;
const totalBatchAnswerMockEntries = 10;

export class BatchAnswerDatasetsResource extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/answer/v1/datasets`;

    listDataset(
        queryParams: DatasetQueryParams,
        mockingParams?: MockResponseParams,
    ): Promise<BatchAnswerGetDatasetListResponse> {
        if (shouldMockBatchAnswer(mockingParams?.isMocking)) {
            return new Promise<BatchAnswerGetDatasetListResponse>((resolve) => {
                setTimeout(() => {
                    resolve(
                        createMockBatchAnswerDatasetListResponse(
                            totalBatchAnswerMockEntries,
                            queryParams.page,
                            queryParams.perPage,
                        ),
                    );
                }, twoSeconds);
            });
        }
        return this.api.get<BatchAnswerGetDatasetListResponse>(
            this.buildPath(BatchAnswerDatasetsResource.baseUrl, queryParams),
        );
    }

    getDataset(
        pathParams: DeleteBatchAnswerDatasetPathParams,
        mockingParams?: MockResponseParams,
    ): Promise<BatchAnswerGetDatasetResponse> {
        if (shouldMockBatchAnswer(mockingParams?.isMocking)) {
            return new Promise<BatchAnswerGetDatasetResponse>((resolve) => {
                setTimeout(() => {
                    resolve(createMockBatchAnswerDataset());
                }, twoSeconds);
            });
        }
        return this.api.get<BatchAnswerGetDatasetResponse>(
            `${BatchAnswerDatasetsResource.baseUrl}/${pathParams.datasetId}`,
        );
    }

    createDataset(
        bodyParams: CreateBatchAnswerDatasetBodyParams,
        mockingParams?: MockResponseParams,
    ): Promise<BatchAnswerGetDatasetResponse> {
        if (shouldMockBatchAnswer(mockingParams?.isMocking)) {
            return new Promise<BatchAnswerGetDatasetResponse>((resolve) => {
                setTimeout(() => {
                    resolve(createMockBatchAnswerDataset());
                }, twoSeconds);
            });
        }
        return this.api.post<BatchAnswerGetDatasetResponse>(BatchAnswerDatasetsResource.baseUrl, bodyParams);
    }

    deleteDataset(pathParams: DeleteBatchAnswerDatasetPathParams, mockingParams?: MockResponseParams): Promise<void> {
        if (shouldMockBatchAnswer(mockingParams?.isMocking)) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, twoSeconds);
            });
        }
        return this.api.delete(`${BatchAnswerDatasetsResource.baseUrl}/${pathParams.datasetId}`);
    }
}
