import {API, Resource} from '@coveo/platform-client';
import {MockResponseParams} from '../../../interfaces';
import {
    BatchAnswerListQueryParams,
    BatchAnswerPathParams,
    BatchAnswerRunBodyParams,
    BatchAnswerRunsListResponse,
    BatchAnswerRunPathParams,
    BatchAnswerRunResponse,
    BatchAnswerRunResultResponse,
} from '../../../interfaces/knowledge/BatchAnswerTypes';
import {shouldMockBatchAnswer} from '../ShouldMockBatchAnswer';
import {
    createMockBatchAnswerRunItem,
    createMockBatchAnswerRunsListResponse,
    createMockBatchAnswerRunResultResponse,
} from './BatchAnswerEvaluationRuns.mocks';

const twoSeconds = 2000;
const totalBatchAnswerMockEntries = 10;

export class EvaluationRunResource extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/answer/v1/evaluations`;

    list(
        pathParams: BatchAnswerPathParams,
        queryParams?: BatchAnswerListQueryParams,
        mockingParams?: MockResponseParams,
    ): Promise<BatchAnswerRunsListResponse> {
        if (shouldMockBatchAnswer(mockingParams?.isMocking)) {
            return new Promise<BatchAnswerRunsListResponse>((resolve) => {
                setTimeout(() => {
                    resolve(
                        createMockBatchAnswerRunsListResponse(
                            mockingParams?.nbOfMockEntry ?? totalBatchAnswerMockEntries,
                            queryParams?.page,
                            queryParams?.perPage,
                        ) as BatchAnswerRunsListResponse,
                    );
                }, twoSeconds);
            });
        }
        return this.api.get<BatchAnswerRunsListResponse>(
            this.buildPath(`${EvaluationRunResource.baseUrl}/${pathParams.evaluationId}/runs`, queryParams),
        );
    }

    create(
        pathParams: BatchAnswerRunPathParams,
        bodyParams: BatchAnswerRunBodyParams,
        mockingParams?: MockResponseParams,
    ): Promise<BatchAnswerRunResponse> {
        if (shouldMockBatchAnswer(mockingParams?.isMocking)) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(createMockBatchAnswerRunItem(0) as BatchAnswerRunResponse);
                }, twoSeconds);
            });
        }
        return this.api.post<BatchAnswerRunResponse>(
            `${EvaluationRunResource.baseUrl}/${pathParams.evaluationId}/runs`,
            bodyParams,
        );
    }

    delete(pathParams: BatchAnswerRunPathParams, mockingParams?: MockResponseParams): Promise<void> {
        if (shouldMockBatchAnswer(mockingParams?.isMocking)) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, twoSeconds);
            });
        }
        return this.api.delete(`${EvaluationRunResource.baseUrl}/${pathParams.evaluationId}/runs/${pathParams.runId}`);
    }

    updateNotes(
        pathParams: BatchAnswerRunPathParams,
        bodyParams: BatchAnswerRunBodyParams,
        mockingParams?: MockResponseParams,
    ): Promise<void> {
        if (shouldMockBatchAnswer(mockingParams?.isMocking)) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, twoSeconds);
            });
        }
        return this.api.put(
            `${EvaluationRunResource.baseUrl}/${pathParams.evaluationId}/runs/${pathParams.runId}/notes`,
            bodyParams,
        );
    }

    results(
        pathParams: BatchAnswerPathParams,
        queryParams?: BatchAnswerListQueryParams,
        mockingParams?: MockResponseParams,
    ): Promise<BatchAnswerRunResultResponse> {
        if (shouldMockBatchAnswer(mockingParams?.isMocking)) {
            return new Promise<BatchAnswerRunResultResponse>((resolve) => {
                setTimeout(() => {
                    resolve(
                        createMockBatchAnswerRunResultResponse(
                            totalBatchAnswerMockEntries,
                            queryParams?.page,
                            queryParams?.perPage,
                        ) as BatchAnswerRunResultResponse,
                    );
                }, twoSeconds);
            });
        }
        return this.api.get<BatchAnswerRunResultResponse>(
            this.buildPath(`${EvaluationRunResource.baseUrl}/${pathParams.evaluationId}/runs/results`, queryParams),
        );
    }
}
