import {createMockListResponse} from '../../../helpers/CreateMockListResponse';

export const createMockBatchAnswerRunItem = (index: number) => ({
    id: `run-${index}`,
    runNumber: index + 1,
    createdOn: '2021-01-01T00:00:00Z',
    createdBy: {userId: `user-${index}`, fullName: `User ${index}`},
    evaluationId: `evaluation-${index}`,
    notes: `Notes for run ${index}`,
    datasetSummary: {
        id: `dataset-${index}`,
        name: `Dataset ${index}`,
        totalQueries: 100 + index,
    },
    queryPipelineSummary: {
        id: `pipeline-${index}`,
        name: `Pipeline ${index}`,
        crgaModels: [],
    },
    facets: [],
    customContext: {},
    credentials: {
        type: 'ANONYMOUS',
    },
    statistics: {
        startDate: '2021-01-01T00:00:00Z',
        answerRate: 0.5,
        isCompleted: true,
    },
});

export const createMockBatchAnswerRunResultItem = (index: number) => ({
    queryId: `query-${index}`,
    query: `Sample query text ${index}`,
    state: 'SUCCESS',
    answer: {
        id: `answer-${index}`,
        text: `Sample answer text ${index}`,
        contentFormat: 'text/plain',
        citations: [
            {
                id: `citation-${index}`,
                title: `Sample citation title ${index}`,
                uri: `http://example.com/citation-${index}`,
                permanentId: `permanent-${index}`,
            },
        ],
    },
});

export const createMockBatchAnswerRunsListResponse =
    createMockListResponse<ReturnType<typeof createMockBatchAnswerRunItem>>(createMockBatchAnswerRunItem);

export const createMockBatchAnswerRunResultResponse = createMockListResponse<
    ReturnType<typeof createMockBatchAnswerRunResultItem>
>(createMockBatchAnswerRunResultItem);
