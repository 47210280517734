import {createMockListResponse} from '../../../helpers/CreateMockListResponse';
import {BatchAnswerGetDatasetResponse} from '../../../interfaces/knowledge/BatchAnswerTypes';

export const createMockBatchAnswerDataset = (index = 0, nbOfQueries = 200): BatchAnswerGetDatasetResponse => ({
    id: `dataset-${index}`,
    name: `Dataset ${index}`,
    queries: Array.from({length: nbOfQueries}, (_, i) => ({text: `Sample query text ${i}`})),
});

export const createMockBatchAnswerDatasetListResponse =
    createMockListResponse<BatchAnswerGetDatasetResponse>(createMockBatchAnswerDataset);
