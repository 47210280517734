import {createMockListResponse} from '../../../helpers/CreateMockListResponse';
import {CreateBatchAnswerBodyParams, CreateBatchAnswerResponse} from '../../../interfaces/knowledge/BatchAnswerTypes';

export const createMockBatchAnswerItem = (index: number) => {
    const mockData = {
        id: `batch-${index}`,
        name: `Batch Answer ${index}`,
        createdOn: '2021-01-01T00:00:00Z',
        createdBy: {
            userId: 'user-Id',
            fullName: 'Daniel Rosenfeld',
        },
        totalRuns: index + 1,
        queryPipeline: {
            id: `pipeline-${index}`,
            name: `Pipeline ${index}`,
        },
        datasetSummary: {
            id: `dataset-${index}`,
            name: `Dataset ${index}`,
            totalQueries: 100 + index,
        },
        facets: [],
        customContext: {},
        credentials: {
            type: 'ANONYMOUS',
        },
        runsStatistics: [
            {
                startDate: '2021-01-01T00:00:00Z',
                answerRate: 0.5,
                isCompleted: true,
            },
        ],
    };
    return mockData;
};

export const createMockBatchAnswerListResponse = (filter?: string) =>
    createMockListResponse<ReturnType<typeof createMockBatchAnswerItem>>(createMockBatchAnswerItem, (item) =>
        item.name.includes(filter ?? ''),
    );

export const createMockEvaluationCreationResponse = (_params: CreateBatchAnswerBodyParams): CreateBatchAnswerResponse =>
    createMockBatchAnswerItem(0) as CreateBatchAnswerResponse;
