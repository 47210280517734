import {PageModel, Paginated} from '@coveo/platform-client';

export type PaginatedResponse<T> = PageModel<T> & Paginated;

export const createMockListResponse =
    <T>(createItem: (...args: unknown[]) => T, filterFunction?: (item: T) => boolean) =>
    (totalEntries: number, page?: number, perPage?: number): PaginatedResponse<T> => {
        let items = Array.from({length: totalEntries}, (_, i) => createItem(i));

        if (filterFunction) {
            items = items.filter(filterFunction);
        }

        const totalEntriesAfterFilter = items.length;

        page = page ?? 0;
        perPage = perPage ?? totalEntriesAfterFilter;
        const paginatedItems = items.slice(page * perPage, (page + 1) * perPage);
        const totalPages = Math.ceil(totalEntriesAfterFilter / perPage) || 0;

        return {
            totalEntries: totalEntriesAfterFilter,
            totalPages,
            page,
            perPage,
            items: paginatedItems,
        };
    };
